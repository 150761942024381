import axios, { AxiosRequestHeaders } from 'axios'
import localStorages from 'utils/local-storage'

const { REACT_APP_APP_NAME, REACT_APP_API_BASE_URL } = process.env;

const BASE_HEADERS: AxiosRequestHeaders = {
    "content-type": "application/json",
    "x-client-id": REACT_APP_APP_NAME || '',
    "x-device-id": REACT_APP_APP_NAME || '',
  };

export const token = async () => {
    return await localStorages().get(REACT_APP_APP_NAME+ ".auth_token");
}

async function getAnomToken(){
    try {
        const res = await axios.post(
            "/user/v2/auth/anonymous",
            {},
            {
                baseURL: REACT_APP_API_BASE_URL,
                headers: BASE_HEADERS,
            }
        )
        localStorages().save(`${REACT_APP_API_BASE_URL}.anom_token`, res.data.content.token)
        return res.data.content.token;
    } catch (error) {
        return "";
    }
}

export const api = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    headers: BASE_HEADERS,
})

api.interceptors.request.use(
    async function(config:any) {
        let newConfig = config;
        const token = await localStorages().get(REACT_APP_APP_NAME + ".auth_token");
        newConfig.headers["x-auth-token"] = token;
        return newConfig;
    },
    function (error:any){
        return Promise.reject(error);
    }
)


api.interceptors.response.use(
    function (response){
        return response;
    },
    function (error) {
        return Promise.reject(error);
    }
)


export const apiAnom = axios.create({
    baseURL: REACT_APP_API_BASE_URL,
    headers: BASE_HEADERS,
})

apiAnom.interceptors.request.use(
    async function (config:any) {
        let newConfig = config;
        const token = await localStorages().get(REACT_APP_API_BASE_URL + ".anom_token");
        newConfig.headers["x-auth-token"] = token;
        return newConfig
    }
)

apiAnom.interceptors.response.use(
    function (response){
        return response;
    },
    async function (error){
        const originalRequest = error.config;
        if (error.response && error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true;
            const access_token = await getAnomToken();
            originalRequest.headers["x-auth-token"] = access_token;
            return apiAnom(originalRequest);
        }
        return Promise.reject(error);
    }
)


export const Service = {
    CHECK_PHONE_REGISTERED: 'user/v2/user/phone/exist',
    USER: 'user/v2/user',
    SEND_OTP: 'user/v2/user/otp/send',
    VERIFY_OTP: 'user/v2/user/otp/verify',
    ME: '/user/v2/user/me',

}






