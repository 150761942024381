import { Image, Space } from 'antd';
import imageLoader from 'static/assets/images/loader.gif';
import './default-loader.less';

export default function DefaultLoader() {
  return (
    <Space
      align="center"
      style={{
        display: 'flex',
        fontSize: 18,
        height: '100vh',
        justifyContent: 'center',
      }}
      className="loader">
      <Image width={'50px'} src={imageLoader} />
    </Space>
  );
}
