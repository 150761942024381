import DefaultLoader from 'components/atoms/DefaultLoader';
import React, {
  cloneElement,
  Component,
  lazy,
  Suspense,
  useEffect,
  useState,
} from 'react';
import { Route, Switch } from 'react-router-dom';
import { token } from 'services/api';
import Cookies from 'js-cookie';
import Axios from 'axios';

const { REACT_APP_APP_NAME, REACT_APP_API_BASE_URL } = process.env;

const LazyComponent = ({ component: Comp, ...rest }: any) => {
  return (
    <Suspense fallback={null}>
      <Comp />
    </Suspense>
  );
};

const ProtectedRoute = (props: any) => {
  const [loading, setLoading] = useState(true);
  let childProps = {};
  useEffect(() => {
    token().then((res) => {
      setLoading(false);
      if (res) {
        setLoading(false);
      } else {
        window.location.href = '/';
      }
    });
  }, []); // eslint-disable-next-line react-hooks/exhaustive-deps
  if (loading) {
    return <DefaultLoader />;
  }
  return cloneElement(props.children, childProps);
};

const PublicRoute = ({ component: Component, ...props }: any) => {
  return (
    <Route
      {...props}
      render={(innerProps: any) => <Component {...innerProps} />}
    />
  );
};

const publicRoutes: { path: string; component: string }[] = [
  { path: '/', component: '/Home' },
];

const privateRoutes = [];

if (!Cookies.get(REACT_APP_APP_NAME + '.anom_token')) {
  Axios(`${REACT_APP_API_BASE_URL}/apps/v1/initials`, {
    method: 'GET',
    headers: {
      'X-Client-Id': REACT_APP_APP_NAME || '',
      'X-Client-Version': '1.0.0',
      'X-Device-Id': REACT_APP_APP_NAME || '',
    },
  }).then((res) => {
    Cookies.set(REACT_APP_APP_NAME + '.anom_token', res.data.content?.token, {
      expires: new Date(res.data.content?.expires_at * 1000),
    });
  });
}

function App() {
  return (
    <Suspense fallback={<DefaultLoader />}>
      <Switch>
        {publicRoutes.map((item, index) => {
          return (
            <Route
              path={'/'}
              key={index}
              component={lazy(
                () => import(`./pages${item.component}`),
              )}></Route>
          );
        })}
      </Switch>
    </Suspense>
  );
}

export default App;
